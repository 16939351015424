/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');
*, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --sidebar-width: 17rem;
    --primary-clr-hsl: 211, 55%;
    --secondary-clr-hsl: 5, 79%;
    --primary-clr: hsl(var(--primary-clr-hsl), 48%);
    --primary-clr-dark: hsl(var(--primary-clr-hsl), 38%);
    --secondary-clr: hsl(var(--secondary-clr-hsl), 65%);
}

body {
    height: 100vh;
    font-size: 1.2rem;
    font-family: 'Quicksand', system-ui, sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* display: flex;
    justify-content: center;
    text-align: center; */
    color: var(--primary-clr-dark);
}

.body-text {
    margin-left: var(--sidebar-width);
}

a {
    text-decoration: none;
    color: var(--primary-clr-dark);
    font-weight: 700;
}

a:hover {
    color: var(--primary-clr);
}

.text-muted {
    opacity: 0.8;
    margin-top: 1rem;
}

.alterations{
    margin: 1rem;
    padding: 1rem;
}

.results {
    margin: 1em;
    padding: 2rem;
}

.light-text {
    opacity: 0.8;
}

/* Forms */

input {
    border: 1px solid hsl(var(--primary-clr-hsl), 50%);    
    padding: 0.5em;
    border-radius: 10px;
    font-size: 1rem;
    font-family: inherit;
    color: var(--primary-clr-dark);
    font-weight: 500;
    margin-right: 0.5em;
    cursor: pointer;
}

input:focus{
    border: 2px solid var(--primary-clr);
}

.form-group {
    display: flex;
    flex-direction: column;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--primary-clr-dark);
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--primary-clr-dark);
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--primary-clr-dark);
  }

.queryForm button {
    margin-left: 1em;
}

.form-select {
    border: 2px solid var(--primary-clr);
    padding: 0.5em;
    border-radius: 10px;
    font-family: inherit;
    font-size: 1rem;
    margin-right: 0.5rem;
    cursor: pointer;
}

.form-select:focus {
    border: 3px solid var(--primary-clr);
}

.form-label {
    font-size: 0.8em;
    margin-right: 0.5em;
    color: var(--primary-clr-dark);
    font-weight: 700;
    cursor: pointer;
}

/* Sidebar */

.sidebar {
    width: var(--sidebar-width);
    background: var(--primary-clr-dark);
    position: fixed;
    text-align: center;
    top: 0;
    left: 0;
    bottom: 0;
    color: #FFF;
}

.logo {
    padding-top: 4rem;
    font-weight: 700;
    font-size: 1.6rem;
    text-shadow: 1px 1px 3px hsla(0, 0%, 0%, 0.4);
}

.sidebar li {
    list-style-type: none;
}

.sidebar-nav {
    color: #fff;
    display: block;
    padding: 1rem;
    margin-top: 0.8em;
}

.sidebar-nav:hover, .sidebar-nav:focus {
    background: hsl(var(--primary-clr-hsl), 20%);
    color: #fff;
    border: 1px solid hsl(var(--primary-clr-hsl), 20%);
}

.active {
    background: hsl(var(--primary-clr-hsl), 20%);
}

.nav-container {
    padding: 0;
    padding-top: 5rem;
    font-size: 1.3rem;
    position: relative;
}

.form-label {
    font-size: 0.8em;
    margin-right: 0.5em;
    color: var(--primary-clr-dark);
    font-weight: 700;
    cursor: pointer;
}

.footer {
    font-size: 0.7em;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

/* Table */

th {
    cursor: pointer;
}

.table-dark {
    --bs-table-bg: hsl(var(--primary-clr-hsl), 38%) !important;
    --bs-table-border-color: hsl(var(--primary-clr-hsl),26%) !important;
}

.sorting_desc, .sorting_asc {
    background-color: hsl(var(--primary-clr-hsl), 25%) !important;
}

/* Buttons */



.btn-custom {
    padding: 8px;
    cursor: pointer;
    font-size: 1rem;
    background-color: var(--secondary-clr);
    border: none;
    color: #fff;
    border-radius: 10px;
    font-family: inherit;
    font-size: 1.1rem;
    font-weight: 700;
    box-shadow: 2px 2px 4px hsl(var(--secondary-clr-hsl), 75%);
    transition: 0.5s;
}

.btn-danger {
    box-shadow: 2px 2px 4px lightgray;
    font-weight: 600;
    text-align: right;
}

.btn-danger:hover, .btn-danger:focus {
    background: transparent;
    border: 1px solid #dc3545;
    color: #dc3545;
}

.btn-login {
    background: var(--primary-clr);
    color: #fff;
    font-weight: 600;
    font-size: 1.1rem;
    box-shadow: 2px 2px 4px lightgray;
    margin-bottom: 1rem;
}

.btn-login:hover, .btn-login:focus {
    background: transparent;
    color: var(--primary-clr);
    border: 1px solid var(--primary-clr);
}

.btn-custom:hover {
    color: var(--secondary-clr);
    /* border: 1px solid var(--secondary-clr); */
    box-shadow: 0 0 0 1px inset hsl(var(--secondary-clr-hsl), 75%), 2px 2px 4px hsl(var(--secondary-clr-hsl), 75%);
    background-color: #fff;
}

.close {
    padding: .5rem;
    color: #fff;
    font-size: 0.8rem;
}

.btn-clipboard {
    border: transparent;
    background-color: transparent;
    padding: 0;
    margin: 0;
}

.clipboard-icon {
    opacity: 0.7;
    transition: all 0.8ms ease-in-out;
}

.clipboard-icon:hover {
    opacity: 1;
    color: var(--secondary-clr);
}

.scroll-btn {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
}

.section-heading {
    font-size: 2rem;
    margin: 1em 0 0.7em 0;
    font-weight: 700;
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80%;
    overflow: hidden;
    overflow-y: scroll;
    width: 40dvw;
    background: #fff;
    border: 1px solid lightgray;
    padding: 2.2rem !important;
    color: black;
    z-index: 9999;
}

.popup::-webkit-scrollbar {
    width: 0.5em;
}
   
.popup::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.popup::-webkit-scrollbar-thumb {
    background-color: var(--primary-clr);
    outline: 1px solid hsl(var(--primary-clr-hsl), 35%);
}

#overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.6); /* Black background with opacity */
    z-index: 5; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }

  .copy-text {
    font-size: 1.2rem;
    font-weight: 600;
    color: #155724;
    background: #d4edda;
    border-radius: 5px;
    padding: 0.35rem;
    margin-left: 1rem;
  }

  /* Pagination */
.page-link {
    color:  var(--secondary-clr);
}

.page-link:hover {
    color: hsl(var(--secondary-clr-hsl), 50%);
}

.page-link:focus {
    box-shadow: 0 0 0 0.25rem hsla(var(--secondary-clr-hsl), 80%, 0.6);
}

.active>.page-link {
    color: #fff;
    background-color: hsl(var(--secondary-clr-hsl), 65%) !important;
    border-color: hsl(var(--secondary-clr-hsl), 50%)  !important;
}

.form-container {
    background-color: var(--primary-clr);
}

.auth-form  {
    background-color: #fff;
    display: grid;
    place-items: center;
    font-size: 1.2rem;
    padding: 2rem;
    max-width: 40rem;
    box-shadow: 0 0 10px 0 hsl(var(--primary-clr-hsl), 32%);
    border: 1px solid hsl(var(--primary-clr-hsl), 40%);
    border-radius: 10px;
}

.auth-form input {
    font-weight: 600;
    width: 25rem;
}

.top-nav {
    float: right;
    font-size: 0.95rem;
    margin-right: 1rem;
}

.text-primary {
    color: var(--secondary-clr) !important;
    font-weight: 700;
}